.logs-container {
  max-height: calc(100vh - 108px);
  overflow-y: auto;
}

.userdata-container {
  max-height: calc(100vh - 458px);
  overflow-y: auto;
  overflow-x: hidden;
}

.cbtn-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.modal-max {
  max-height: 70vh;
  overflow-y: auto;
}

.profile-info {
  bottom: -16px;
  right: 0;
}

.pkg-card-container {
  border-radius: 6px;
  background-color: #e5e5e5;
  padding: 10px;
}

.pkg-card-container.inactive {
  border-left: 3px solid #dc3545;
}

.pkg-card-container.active {
  border-left: 3px solid #28a745;
}

.hover-info {
  border-bottom: 1px dotted black;
  color: darkblue;
  cursor: help;
}
